import { render, staticRenderFns } from "./NativeTags.vue?vue&type=template&id=4e6dcf6e"
import script from "./NativeTags.vue?vue&type=script&lang=js"
export * from "./NativeTags.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports